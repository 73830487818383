<template>
  <div class="recharge">

    <div class="h5header">
      <img src="../../public/images/new/backicon.png" alt="" class="return" @click="$router.go(-1)" />
      <div class="name flexcenter">{{ $t('asset.desc53') }}</div>
      <div class="lang">
        <img src="../../public/images/new/recordicon.png" alt=""
          @click="$router.push('/rechange/record?id=' + iconId)" />
      </div>
    </div>
    <div class="wrap">
      <div class="wrap-title"><span @click="$router.go(-1)">{{ $t('asset.desc52') }}</span> > <span>{{ $t('asset.desc53')
      }}</span></div>
      <div class="wrap-con flex">
        <div class="wrap-left">
          <div class="active-rules" v-if="coinName== 'DU' && isactive">
            <div class="rules-tag flexcenter">{{ $t('asset.desc148') }}</div>
              <div class="rules-title">{{ $t('asset.desc146',{n1:'DU',n2:configInfo.ratio*100}) }}</div>
              <div class="rules-desc">
                <div>{{ $t('asset.desc147',{n1:iconItem.rechargeCoinName,n2:configInfo.ratio*100,n3:configInfo.awardAccountTypeName,n4:configInfo.awardCoinName}) }}</div>
                <div>{{ $t('asset.desc149') }}{{ common.formatDate(configInfo.startTime) }} - {{ common.formatDate(configInfo.endTime) }} (GST)</div>
              </div>
              <div class="rules-btn flex" @click="showRules=true">
                {{ $t('asset.desc157') }} <img src="../../public/images/new/righticon8.png" alt="" />
              </div>
          </div>
          <div class="step flex">
            <div class="step-line">
              <img src="../../public/images/new/dui.png" alt="" />
            </div>
            <div class="step-info">
              <div class="info-name">{{ $t('asset.desc54') }}</div>
              <div class="info-select">
                <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                    <div class="icon-name flex">
                      <img :src="iconItem.rechargeCoinIcon" alt="" />{{ iconItem.rechargeCoinName }}

                    </div>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>

                      <div class="select-search">
                        <div class="name">{{ $t('swap.desc6') }}</div>
                        <div class="input flex">
                          <img src="../../public/images/new/searchicon1.png" alt="" />
                          <input type="text" :placeholder="$t('asset.desc59')" v-model="searchText" />
                        </div>
                      </div>

                      <el-dropdown-item :command="item.rechargeCoinId" v-for="item in iconList" v-if="searchText == ''">
                        <div class="coin-info flex">
                          <img :src="item.rechargeCoinIcon" alt="" />{{ item.rechargeCoinName }}
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item :command="item.rechargeCoinId" v-for="item in coinShowList" v-else>
                        <div class="coin-info flex">
                          <img :src="item.rechargeCoinIcon" alt="" />{{ item.rechargeCoinName }}
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <div class="select-down"><img src="../../public/images/select-down.png" alt=""></div>
             
              </div>
            </div>
          </div>
          <div class="step flex">
            <div class="step-line"><img src="../../public/images/new/dui.png" alt="" /></div>
            <div class="step-info">
              <div class="info-name">{{ $t('asset.desc55') }}</div>
              <div class="info-nav flex">
                <div class="flexcenter" v-for="item in chainList" :class="{ chainact: item.chainName == chainName }"
                  :key="item.id" @click="handlerChain(item.chainName)">
                  {{ item.tokenType }}</div>
              </div>
              <!-- <div class="info-select">
                <div class="select-check"> {{ chainNameType }}</div>
                <div class="select-icon"></div>
                <div class="select-list">

                  <div class="list">
                    <div class="list-info flex" v-for="item in chainList" :key="item.coinIcon"
                      @click="handlerChain(item.chainName, item.tokenType)">
                      {{ item.chainName }} - {{ item.tokenType }}
                    </div>

                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="step flex">
            <div class="step-line"><img src="../../public/images/new/dui.png" alt="" /></div>
            <div class="step-info">
              <div class="info-name">{{ $t('asset.desc56') }}</div>
              <div class="info-tips">{{ $t('asset.desc57',{n1:coinName,n2:textName}) }}</div>
            </div>
          </div>
          <div class="step flex">
            <div class="step-line">4</div>
            <div class="step-info">
              <div class="info-name">{{ $t('asset.desc58') }}</div>
              <div class="info-address flex">
                <div class="address-copy">
                  <div class="copy-name">{{ $t('record.desc9') }}</div>
                  <div class="copy-info">{{ address }}</div>
                  <div class="copy-btn flex"><img src="../../public/images/new/copy1.png" alt=""
                      @click="copy($event, address)" /> {{ $t('asset.desc51') }}
                  </div>
                </div>
                <div class="address-down">
                  <div class="qrcode">
                    <vue-qr :text="address" :size="100" :margin="0" :callback="qrBack">
                    </vue-qr>
                  </div>
                  <div class="down-btn" @click="downQr">{{ $t('asset.desc21') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap-right">
          <div class="right-name">{{ $t('asset.desc82') }}</div>
          <div class="right-tips">{{ $t('asset.desc60') }}</div>
          <div class="right-tips">{{ $t('asset.desc61') }}</div>
          <div class="right-tips">{{ $t('asset.desc64') }}</div>
          <div class="right-tips">{{ $t('asset.desc62') }}</div>
          <div class="right-tips">{{ $t('asset.desc63') }}</div>
          <!-- <div class="right-tips">{{ $t('asset.desc64', { n1: iconItem.rechargeCoinName }) }}</div>
          <div class="right-tips">{{ $t('asset.desc65') }}</div> -->
          <div class="right-rules" v-if="coinName== 'DU' && isactive">{{ $t('asset.desc150') }}</div>
          <div class="rules-desc" v-if="coinName== 'DU' && isactive">
            <div>{{ $t('asset.desc151') }} {{ common.formatDate(configInfo.startTime) }} - {{ common.formatDate(configInfo.endTime) }}(GST)</div>
            <div>{{ $t('asset.desc152',{n1:iconItem.rechargeCoinName}) }}</div>
            <div>{{ $t('asset.desc153',{n1:configInfo.ratio * 100,n2:iconItem.rechargeCoinName}) }}</div>
            <div>{{ $t('asset.desc154',{n1:configInfo.awardCoinName}) }}</div>
            <div>{{ $t('asset.desc155') }} <span v-if="configInfo.awardAccountType==1">{{ $t('new.desc7') }}</span>
              <span v-else-if="configInfo.awardAccountType==2">{{ $t('new.desc8') }}</span>
              <span v-else>{{ $t('new.desc9') }}</span>
            </div>
            <div>{{ $t('asset.desc156') }}</div>
          </div>
        </div>
      </div>
      <div class="wrap-record">
        <div class="record-title flex">
          <div>{{ $t('asset.desc81') }}</div>

        </div>
        <rechargeRecord :iconId="iconId" />
      </div>
    </div>
    <!--  -->
    <van-popup v-model:show="showRules" position="bottom">
      <div class="rules-modal">
        <div class="modal-title flex">
          <div>{{ $t('asset.desc150') }}</div>
          <img src="../../public/images/new/closeicon3.png" alt="" @click="showRules=false" />
        </div>
        <div class="rules-desc">
          <div>{{ $t('asset.desc151') }} {{ common.formatDate(configInfo.startTime) }} - {{ common.formatDate(configInfo.endTime) }}（GST）</div>
            <div>{{ $t('asset.desc152',{n1:iconItem.rechargeCoinName}) }}</div>
            <div>{{ $t('asset.desc153',{n1:configInfo.ratio * 100,n2:iconItem.rechargeCoinName}) }}</div>
            <div>{{ $t('asset.desc154',{n1:configInfo.awardCoinName}) }}</div>
            <div>{{ $t('asset.desc155') }} <span v-if="configInfo.awardAccountType==1">{{ $t('new.desc7') }}</span>
              <span v-else-if="configInfo.awardAccountType==2">{{ $t('new.desc8') }}</span>
              <span v-else>{{ $t('new.desc9') }}</span>
            </div>
            <div>{{ $t('asset.desc156') }}</div>
          </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Clipboard from '@/utils/libs/clipboard'
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import rechargeRecord from './rechargerecord.vue'
export default {
  components: {
    VueQr,
    rechargeRecord
  },
  data() {
    return {
      showRules:false,
      num: 0,
      address: '',
      qrImg: '',
      isShowChainListPopup: false,
      isShowBiListPopup: false,
      iconId: '',
      iconItem: {},
      iconList: [],
      chainList: [],
      chainName: '',
      chainItem: {},
      chainInfo: {},
      searchText: '',
      coinShowList: [],
      walletTypeCode: '',
      chainNameType: '',
      showList: false,
      coinName:'',
      textName:'',
      configInfo:{},
      isactive:false
    }
  },
  watch: {
    searchText(val) {
      this.selectCoin(val)
    },
  },
  mounted() {
    this.iconId = this.$route.query.id;
    this.getinfo()
    this.$post(this.URL.assets.rechargeConfig,{
      activityType:1
    }).then(res =>{
      if(res.code==0 && res.data != null){
        this.isactive=true;
        let time1 = 4 * 60 * 60 * 1000;
        res.data.startTime=res.data.startTime-time1;
        res.data.endTime=res.data.endTime-time1;
        if(res.data.awardAccountType==1){
          res.data.awardAccountTypeName=this.$t('new.desc7')
        }else if(res.data.awardAccountType==2){
          res.data.awardAccountTypeName=this.$t('new.desc8')
        }else{
          res.data.awardAccountTypeName=this.$t('new.desc9')
        }
        this.configInfo=res.data;
      
      }
    })
  },
  methods: {
    handleCommand(val) {
      this.chooseBiItem(val)
    },
    selectCoin(name) {

      if (name) {
        let arr = this.iconList
        let newArr = []
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].rechargeCoinName.toUpperCase().indexOf(name.toUpperCase()) != -1) {
            newArr.push(arr[i])
          }
        }
        this.coinShowList = newArr
      } else {
        this.coinShowList = this.iconList
      }
    },
    chooseBiItem(id) {
      let info = this.iconList.find(item => item.rechargeCoinId == id);
      this.chainList = info.rechargeChainData;
      this.chainName = this.chainList[0].chainName;
      this.iconItem = info;
      this.iconId = this.iconItem.rechargeCoinId;
      this.walletTypeCode = this.chainList[0].walletTypeCode;
      this.showList = false;
      this.getAddress();
    },
    getinfo() {
      this.$post(this.URL.assets.view, {
        relationCoinId: this.$route.query.id,
        accountType: this.$route.query.type
      }).then(res => {
        if (res.code == 0) {
          this.iconList = res.data.rechargeCoinDataList;
          this.coinName=res.data.coinName;
          
          this.iconList.forEach( (e,i) =>{
            if(i==this.iconList.length -1){
              this.textName+=`${e.rechargeCoinName}`
            }else{
              this.textName+=`${e.rechargeCoinName}、`
            }
           
          })
          this.chainList = this.iconList[0].rechargeChainData;
          this.chainName = this.chainList[0].chainName;
          this.iconItem = this.iconList[0];
          this.iconId = this.iconItem.rechargeCoinId;
          this.walletTypeCode = this.chainList[0].walletTypeCode
          this.getAddress()
        } else {
          this.$message.error(res.messgae)
        }
      })
    },
    handlerChain(name) {
      this.chainName = name;
      this.getAddress()
    },

    async getAddress() {
      let res = await this.$post(
        this.URL.assets.rechargeAddress,
        {
          coinId: this.$route.query.id,
          chainName: this.chainName,
          walletType: this.walletTypeCode,
          accountType: this.$route.query.type,
          rechargeCoinId: this.iconId
        });
      if (res.code == 0) {
        this.chainInfo = res.data;
        this.address = this.chainInfo.address
      } else {
        this.$message.error(res.messgae)
      }

    },
    copy(e, text) {
      // @click="copy($event,googleRandom.secretKey)"
      let that = this;
      Clipboard(e, text).then((res) => {
        that.$message.success(this.$t('planet.desc61'))
      });
    },
    qrBack(dataUrl, id) { // 缓存二维码数据
      this.qrImg = dataUrl
    },
    downQr() { // 下载二维码
      let name = new Date().getTime();
      let a = document.createElement("a");
      a.style.display = "none";
      a.download = name;
      a.href = this.qrImg;
      document.body.appendChild(a);
      a.click();
    },



    jump_link(url, type) {
      this.$router.replace({
        path: url,
        query: {
          type: type,
        }
      });
    },
    dealAddress(str) {
      if (str && str.length > 31) {
        return str.substring(0, 18) + '....' + str.substring(str.length - 13, str.length)
      } else {
        return str
      }
    },
  }
}
</script>

<style lang="less" scoped>
.recharge {
  padding: 116px 0 69px;
  width: 100%;
  min-height: 100vh;
  background: url('../../public/images/new/landbg.png') center no-repeat;
  background-size: 100% 100%;

  .wrap {
    width: 1240px;
    margin: 0 auto;

    .wrap-title {
      color: #A0A0A0;
      font-size: 13px;

      span {
        &:first-child {
          cursor: pointer;
        }

        &:last-child {
          color: #fff;
        }
      }
    }

    .wrap-con {
      margin-top: 32px;
      justify-content: space-between;

      .wrap-left {
        flex: 0 0 514px;
        .active-rules{
          position: relative;
          padding: 20px 5px 20px 20px;
          margin-bottom: 22px;
          border:1px solid rgba(255, 255, 255, 0.2);
          .rules-btn{
            display: none;
          }
          .rules-tag{
            position: absolute;
            top: 0;
            right: 0;
            width: 82px;
            height: 30px;
            border-radius: 0px 0px 0px 12px;
            background: #C9FA5B;
            font-size: 14px;
            color: #000000;
            text-align: center;
          }
          .rules-title{
            margin-bottom: 20px;
            font-size: 20px;
            color: #C9FA5B;
            line-height: 20px;
          }
          .rules-desc{
            font-size: 14px;
            color: #A8A8A8;
            div{
              &:last-child{
                margin-top: 12px;
              }
            }
          }
        }
        .step {
          position: relative;
          padding-bottom: 32px;

          &::after {
            content: '';
            position: absolute;
            top: 22px;
            left: 10px;
            width: 1px;
            height: 100%;
            background: #C9FA5B;
          }

          &:last-child {
            padding-bottom: 0;

            &::after {
              width: 0;
            }

            .step-line {
              height: 22px;
              background: #C9FA5B;
              border-radius: 50%;
              font-size: 14px;
              color: #000;
              text-align: center;
              line-height: 22px;
            }
          }

          .step-line {
            flex: 0 0 22px;
            margin-right: 12px;

            img {
              width: 22px;
              height: 22px;
            }
          }

          .step-info {
            flex: 0 0 480px;

            .info-name {
              font-size: 18px;
              color: #FFFFFF;
              line-height: 18px;
              margin-bottom: 22px;
            }

            .info-nav {
              div {
                flex: 0 0 105px;
                height: 40px;
                margin-right: 20px;
                font-size: 16px;
                color: #FFFFFF;
                border: 1px solid rgba(255, 255, 255, 0.2);
                cursor: pointer;

                &:last-child {
                  margin-right: 0;
                }
              }

              .chainact {
                position: relative;
                border: 1px solid #C9FA5B;
                color: #C9FA5B;

                &::after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  width: 16px;
                  height: 16px;
                  background: url('../../public/images/new/duiicon.png') center no-repeat;
                  background-size: 100% 100%;
                }
              }
            }

            .info-tips {
              font-size: 18px;
              color: #C9FA5B;
            }

            .info-address {
              padding: 20px;
              border: 1px solid rgba(255, 255, 255, 0.2);

              .address-copy {
                flex: 1;
                overflow: hidden;

                .copy-name {
                  font-size: 14px;
                  color: #A8A8A8;
                }

                .copy-info {
                  padding: 16px 0 0;
                  font-size: 16px;
                  color: #FFFFFF;
                }

                .copy-btn {
                  margin-top: 80px;
                  font-size: 14px;
                  color: #fff;
                  line-height: 14px;

                  img {
                    width: 12px;
                    height: 12px;
                    margin-right: 8px;
                    cursor: pointer;
                  }
                }
              }

              .address-down {
                flex: 0 0 120px;
                margin-left: 40px;

                .qrcode {
                  padding: 10px;
                  background: #fff;
                }

                .down-btn {
                  margin-top: 13px;
                  height: 38px;
                  background: url('../../public/images/new/btnbg9.png') center no-repeat;
                  background-size: 100% 100%;
                  text-align: center;
                  line-height: 38px;
                  font-size: 14px;
                  color: #000000;
                  cursor: pointer;
                }
              }
            }

            .info-select {
              position: relative;
              padding: 12px 16px;
              height: 48px;
              border: 1px solid rgba(255, 255, 255, 0.20);
              box-sizing: border-box;
              cursor: pointer;
              .select-down {
                position: absolute;
                top: 50%;
                right: 21px;
                transform: translateY(-50%);
                img {
                  width: 10px;
                  height: 6px;
                }
              }


            }
          }
        }
      }

      .wrap-right {
        flex: 0 0 420px;
        padding: 24px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        height: fit-content;

        .right-name {
          font-size: 18px;
          color: #FFFFFF;
          margin-bottom: 20px;
        }

        .right-tips {
          font-size: 14px;
          color: #A8A8A8;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
        .right-rules{
          padding-top: 12px;
          font-size: 16px;
          color: #C9FA5B;
        }
        .rules-desc{
          font-size: 14px;
          color: #A8A8A8;
          div{
            margin-top: 8px;
          }
        }
      }
    }

    .record-title {
      margin: 40px 0 16px;
      justify-content: space-between;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 23px;

      p {
        font-size: 14px;
        cursor: pointer;

        img {
          width: 5px;
          height: 8px;
          margin: 7px 0 0 6px;
        }
      }
    }
  }
}

.h5header {
  display: none;
}

::v-deep .el-dropdown {
  width: 100%;
  border: 0 !important;

  .el-dropdown-link {
    width: 100%;
    border: 0 !important;
    
    .icon-name {
      width: 100%;
      font-size: 16px;
      color: #fff;
      line-height: 24px;
      border: 0px solid #eee !important;
      img {
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}

.coin-info {
  width: 446px;
  font-size: 16px;
  color: #fff;
  line-height: 24px;

  &:hover {
    background: #292929 !important;
  }

  img {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.select-search {
  padding: 0 16px 6px;
  width: 446px;

  .name {
    padding-top: 20px;
    font-size: 18px;
    color: #FFFFFF;
  }

  .input {
    margin-top: 20px;
    width: 100%;
    padding: 13px 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;

    img {
      flex: 0 0 18px;
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }

    input {
      flex: 1;
      height: 18px;
      font-size: 16px;
      color: #fff;

      &::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
.rules-modal{
  padding: 40px 16px;
  .modal-title{
    margin-bottom: 20px;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 18px;
    div{
      flex: 1;
    }
    img{
      flex: 0 0 12px;
      width: 12px;
      height: 12px;
      margin: 3px 0 0 4px;
    }
  }
  .rules-desc{
    font-size: 14px;
    color: #A8A8A8;
    div{
      margin-bottom: 8px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width:1200px) {
  .recharge {
    padding: 88px 16px 70px;

    .wrap {
      width: 100%;

      .wrap-con {
        margin-top: 16px;
        flex-wrap: wrap;

        .wrap-left {
          flex: 0 0 100%;
        }

        .wrap-right {
          margin-top: 20px;
          padding: 14px;
          border: 0;
        }
      }

    }
  }
}

@media (max-width:767px) {
  .coin-info ,.select-search {
    width: 100%;
  }
  .h5header {
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: #000;
    z-index: 888;
    display: block;

    .return {
      position: absolute;
      top: 50%;
      left: 16px;
      width: 14px;
      height: 22px;
      transform: translateY(-50%);
    }

    .name {
      width: 80%;
      margin: 0 auto;
      font-size: 16px;
      color: #fff;
      height: 44px;
    }

    .lang {
      position: absolute;
      top: 50%;
      right: 16px;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);

      img {
        width: 20px;
        height: 20px;
      }
    }

  }

  .recharge {
    padding: 56px 16px;

    .wrap {
      .wrap-title {
        display: none;
      }

      .wrap-con {
        .wrap-left {
          .active-rules{
            padding: 16px;
            .rules-title{
              padding-right: 60px;
              margin-bottom: 16px;
              font-size: 16px;
              line-height: 16px;
            }
            .rules-desc{
              font-size: 13px;
              div{
                &:last-child{
                  margin-top: 8px;
                }
              }
            }
            .rules-btn{
              display: flex;
              margin-top: 16px;
              font-size: 14px;
              color: #C9FA5B;
              line-height: 14px;
              img{
                width:8px;
                height: 13px;
                margin:1px 0 0 8px;
              }
            }
          }
          .step {
            padding-bottom: 24px;

            .step-info {
              flex: 1;

              .info-name {
                font-size: 16px;
                margin-bottom: 16px;
              }

              .info-select {
                .select-list {
                  padding: 14px 0;

                  .select-search {
                    padding: 0 14px;

                    .name {
                      font-size: 16px;
                    }

                    .input {
                      margin-top: 10px;
                    }
                  }
                }
              }

              .info-nav {
                flex-wrap: wrap;

                div {
                  flex: 0 0 32%;
                  margin-right: 2%;
                  height: 36px;
                  font-size: 14px;
                  margin-top: 10px;

                  &:nth-child(-n+3) {
                    margin-top: 0;
                  }

                  &:nth-child(3n) {
                    margin-right: 0;
                  }
                }
              }

              .info-tips {
                font-size: 16px;
              }

              .info-address {
                padding: 0;
                border: 0;
                flex-wrap: wrap;

                .address-copy {
                  flex: 0 0 100%;
                  padding: 14px;
                  border: 1px solid rgba(255, 255, 255, 0.2);
                  box-sizing: border-box;

                  .copy-btn {
                    margin-top: 16px;
                  }

                  .copy-info {
                    padding-top: 8px;
                  }
                }

                .address-down {
                  flex: 0 0 100%;
                  margin-left: 0;
                  margin-top: 20px;
                  display: flex;

                  .qrcode {
                    flex: 0 0 120px;

                  }

                  .down-btn {
                    flex: 0 0 142px;
                    height: 40px;
                    margin: 80px 0 0 20px;
                  }
                }
              }
            }
          }
        }

        .wrap-right {
          flex: 0 0 100%;
          padding: 14px;
          background: #181818;
          border-radius: 8px;

          .right-name {
            margin-bottom: 16px;
            font-size: 16px;
          }

          .right-tips {
            font-size: 13px;
          }
          .right-rules,.rules-desc{
            display: none;
          }
        }
      }

      .wrap-record {
        display: none;
      }
    }
  }
}
</style>